@font-face {
    font-family: PFDinDisplayPro;
    src: url('assets/fonts/pfdindisplaypro-light.woff2') format('woff2'),
        url('assets/fonts/pfdindisplaypro-light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: PFDinDisplayPro;
    src: url('assets/fonts/pfdindisplaypro-regular.woff2') format('woff2'),
        url('assets/fonts/pfdindisplaypro-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: PFDinDisplayPro;
    src: url('assets/fonts/pfdindisplaypro-medium.woff2') format('woff2'),
        url('assets/fonts/pfdindisplaypro-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: PFDinDisplayPro;
    src: url('assets/fonts/pfdindisplaypro-bold.woff2') format('woff2'),
        url('assets/fonts/pfdindisplaypro-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}


body {
  margin: 0;
  padding: 0;
  font-family: PFDinDisplayPro, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

code {
  font-family: PFDinDisplayPro, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*
html, body {
    min-height: 100%;
    height: 100%;
    overflow-y: auto;
}

body, .page-main {
    display: flex;
    flex-direction: column;
}

#root {
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 100%;
}

#root .page {
    justify-content: flex-start;
} */
.page, .page-content.welcome {
    flex: 1 1 auto;
}

.page > footer {
    background-color: #84BD43;
    color: white;
}
/*
.page > footer .col-lg-auto {
    flex: 1 1 auto;
} */

.page-content {
    padding-top: 40px !important;
}

.page-content.welcome {
    margin: 0;
    background: #fff;
    padding: 1em;
    text-align: center;
}

.page-content.welcome h1,
.page-content.welcome p {
    margin: 0 auto 0.5em;
}

.page-content.welcome p {
    font-size: 18px;
    text-align: "justify";
}

.page-content.welcome h1{
    max-width: 800px;
    text-align: left;
}

.page-content.welcome h2{
    margin-top: 60px;
    font-size: 32px;
}

.btn-large {
    display: inline-block;
    margin: 2em auto;
    padding: 1em 1.5em;
    background: #90B93F;
    color: #fff;
    text-transform: uppercase;
    border-radius: 50px;
    letter-spacing: 0.25px;
}

.btn-large:hover {
    background: #97c046;
    color: #fff;
    text-decoration: none;
}

.partner-logos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
}

.partner-logos img {
    flex: 1 1 auto;
    width: 30%;
    min-width: 180px;
    max-width: 40%;
    height: 100%;
}

.discreet-link {
    color: #fff;
}

.discreet-link:hover {
    color: #777;
}

.page-main {
    flex: 0 0 none !important;
}

.intro {
    padding: 30px 0 0 0;
    text-align: left;
}

.footer .ml-auto {
    display: none;
}

.footer .row {
    justify-content: center;
}

.py-4 {
    display: none;
}

.header .ml-auto {
    display: none;
}

.header {
    font-weight: 400;
    background: #0F9148 !important;
    background: linear-gradient(90deg, rgba(126,195,100,1) 0%, rgba(41,115,57,1) 100%);
}

.header .container {
    margin-left: 200px;
    margin-right: 20px;
}

.header .nav {
    color: #fff;
}

.page .header .nav .nav-link.active {
    color: #fff;
    border-bottom-color: #fff;
}

.page .header .nav a {
    padding: 0 0 0 0 !important;
}